import { apiClient } from "@netgame/openapi";

const DELAY_TIME = 2000;

const useOnboarding = ({
	locationKey,
	actionKeys,
	finalFlow
}: { locationKey?: string; actionKeys?: string[]; finalFlow?: boolean } = {}) => {
	const activeTimer = ref<ReturnType<typeof setTimeout>>();
	const isOpened = ref(false);
	const dontShowAgain = ref(false);
	const actionIndex = ref(0);

	const { data: onboardData } = useGetAllBoardData();

	const isCompleteMode = computed(() => onboardData.value?.result?.some((element) => !!element?.complete));
	const getCurrentOnboard = computed(() =>
		onboardData.value?.result?.find((element) => {
			if (!locationKey) {
				return undefined;
			}
			if (!actionKeys?.length) {
				return element.location === locationKey;
			}
			if (!!actionKeys?.length && element.location === locationKey) {
				return !!(actionKeys || [])[actionIndex.value]?.includes(element.action || "");
			}
			return undefined;
		})
	);

	const openCurrentPopper = () => {
		if (!locationKey) {
			return;
		}
		if (onboardData.value?.result?.length) {
			isOpened.value = true;
		}
	};

	const closeCurrentPopper = async (locationProp: string) => {
		if (!locationKey) {
			return;
		}

		if (!onboardData.value?.result?.length) {
			isOpened.value = true;
		}

		await apiClient({
			path: "/rest/player/set-boarded/",
			method: "post",
			parameters: {
				body: {
					scenario: "introQuest",
					location: getCurrentOnboard.value?.location || "",
					...(!!getCurrentOnboard.value?.action && { action: getCurrentOnboard.value?.action }),
					...(!!dontShowAgain.value && { show: !dontShowAgain.value })
				}
			}
		});

		if (dontShowAgain.value && !isCompleteMode.value) {
			onboardData.value.result = [];
			return;
		}

		dontShowAgain.value = false;

		onboardData.value.result = onboardData.value?.result?.filter((element) => {
			if (!!locationProp && !actionKeys?.length) {
				return element.location !== locationProp;
			}
			if (!!locationProp && !!actionKeys?.length && element.location === locationProp) {
				return !(actionKeys || [])[actionIndex.value]?.includes(element.action || "");
			}
			return true;
		});

		actionIndex.value += 1;
	};

	const checkRulesEqual = () => {
		if (!!finalFlow && actionKeys?.length && onboardData.value?.result?.length) {
			for (let i = 0; i < actionKeys.length; i++) {
				const findKey = onboardData.value?.result?.some((item) => item?.action === actionKeys[i]);
				if (findKey) {
					actionIndex.value = i;
					return;
				}
			}
		}
	};

	watch(
		() => onboardData.value?.result,
		() => {
			checkRulesEqual();
			clearTimeout(activeTimer.value);
			activeTimer.value = setTimeout(() => {
				openCurrentPopper();
			}, DELAY_TIME);
		},
		{ deep: true }
	);

	onMounted(() => {
		checkRulesEqual();
		activeTimer.value = setTimeout(() => {
			openCurrentPopper();
		}, DELAY_TIME);
	});

	onUnmounted(() => {
		clearTimeout(activeTimer.value);
	});

	return {
		onboardData,
		dontShowAgain,
		getCurrentOnboard,
		isOpened,
		closeCurrentPopper
	};
};

export default useOnboarding;
