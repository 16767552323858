<script setup lang="ts">
const props = withDefaults(
	defineProps<{
		isTarget?: boolean;
		locationKey: string;
		actionKey?: string[];
		finalFlow?: boolean;
	}>(),
	{
		isTarget: true
	}
);

const { getCurrentOnboard, dontShowAgain, isOpened, closeCurrentPopper } = useOnboarding({
	locationKey: props.locationKey,
	actionKeys: props.actionKey,
	finalFlow: props.finalFlow
});
const { data: questData } = useGetQuestData();

const isLastStepIntroMode = computed(() => questData.value?.data?.questInfo?.type !== "intro" && props.finalFlow);

const onboardImg = computed(() => {
	if (props.locationKey === "modalGames") {
		return "/nuxt-img/quest/balance-fc.png";
	}
	if (props.locationKey === "game" && getCurrentOnboard.value?.action === "finish") {
		return "/nuxt-img/quest/balance-cc.png";
	}
	return "";
});

const handleDontShowAgain = (showVal: boolean) => {
	dontShowAgain.value = showVal;
};
</script>

<template>
	<div :class="['wrapper-onboard', { 'onboard-active': isOpened && getCurrentOnboard }]">
		<slot />
		<template v-if="isTarget && isOpened && getCurrentOnboard">
			<MQuestsOnboard
				:title="getCurrentOnboard?.title || ''"
				:subTitle="getCurrentOnboard?.subTitle || ''"
				:subTitleMobile="getCurrentOnboard?.subTitleMobile || ''"
				:buttonText="getCurrentOnboard?.buttonText || ''"
				:imgPath="onboardImg"
				:isOpened="isOpened"
				:isLastStepIntroMode="isLastStepIntroMode"
				@dont-show="handleDontShowAgain"
				@close="closeCurrentPopper(getCurrentOnboard?.location || '')"
			/>
			<AOverlay bg-color="var(--ceuta)" class="onboard-overlay" :modifiers="['flex-center', 'fixed', 'auto']" />
		</template>
	</div>
</template>

<style lang="scss" scoped>
.wrapper-onboard {
	position: relative;

	&.onboard-active {
		z-index: 10002;

		:slotted(div) {
			position: relative;
			z-index: 10002;
		}
	}
}

.onboard-overlay {
	z-index: 10001;
}
</style>
